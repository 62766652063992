export default [
  'distributor',
  'distributor_redeem',
  'tokopedia',
  'tokpedia',
  'tokopedia_id',
  'tokped_id',
  'tokpedia_id',
  'tiktok',
  'tiktok_id',
  'shopee_id',
  'shopee',
  'blibli',
  'blibli_id',
  'lazada',
  'lazada_id',
]