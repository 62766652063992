<template>
  <img
    :class="{ imgLoading: load }"
    :src="imgSrc"
    :loading="loading"
    :draggable="draggable"
    @error="onError"
    @load="onLoad"
  />
</template>

<script>
export default {
  // name: "PcaImage",
  props: {
    src: {
      type: [String, Array],
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    draggable: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      imgSrc: this.src,
      load: true,
    }
  },
  methods: {
    onLoad(e) {
      this.load = false
      this.$emit('load', e)
    },
    onError(e) {
      this.imgSrc = this.$defaultImage
      this.$emit('error', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.imgLoading {
  background: linear-gradient(90deg,#f2f2f2 25%,#e6e6e6 37%,#f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
  // cursor: progress;
  // position: relative;
}

// .imgLoading::after {
//   content: '';
//   display: block;
//   position: absolute;
//   inset: 0;
//   // z-index: 1;
// }
</style>
