<template>
  <!-- :keyboard="!loading && !loadingStart" -->
  <!-- :mask-closable="!loading && !loadingStart" -->
  <a-modal
    centered
    :closable="false"
    :footer="null"
    :width="450"
    :keyboard="false"
    :mask-closable="false"
    :visible="visible"
    @cancel="$emit('cancel')"
  >
    <div class="px-3">
      <div class="text-center px-2">
        <div class="d-inline-flex justify-content-center align-items-center rounded-circle wrap-icon">
          <a-icon
            v-if="icon && typeof icon === 'string'"
            :type="icon"
            class="text-white"
            style="font-size: 3rem"
          />
          <slot name="icon" />
        </div>

        <h5 v-if="title" class="my-3">
          {{ title }}
        </h5>
        <div v-if="mainTitle" class="mainTitle">
          {{ mainTitle }}
        </div>
        <p v-if="description" class="text-muted px-5">
          {{ description }}
        </p>

        <slot />
      </div>

      <div class="d-flex flex-column px-2">
        <a-button
          class="mt-2"
          size="large"
          type="primary"
          :loading="loading"
          @click="$emit('ok')"
        >
          {{ okLabel }}
        </a-button>
        <a-button
          size="large"
          class="mt-2"
          :disabled="loading"
          @click="$emit('cancel')"
        >
          {{ cancelLabel }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    okLabel: {
      type: String,
      default: '',
    },
    cancelLabel: {
      type: String,
      default: '',
    },
    mainTitle: {
      type: String,
      default: '',
    },
  },
  emits: ['ok', 'cancel'],
}
</script>

<style lang="scss" scoped>
.wrap-icon {
  width: 90px;
  height: 90px;
  background: #FF0000; // For not support linear-gradient
  background: linear-gradient(153.43deg, #FF8585 0%, #FF0000 83.33%)
}
.mainTitle {
  background: #ffefef;
  border: 1px solid #FF0000;
  padding: 10px;
  font-size: 18px;
}
</style>