import apiClient from '@/services/axios'

export const getKinoSfa = (params) => {
  return apiClient({
    method: 'get',
    url: '/sfa/hook/',
    params,
  })
}

export const resendKinoSfa = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: '/sfa/hook/resend',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}