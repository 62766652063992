import apiClient from '@/services/axios'

export const getDashboard = ({ type, params }) => {
  return apiClient({
    method: 'get',
    url: `bi/dashboard/dash/chart/${type}`,
    params,
  })
}

export const getDashboardChannels = (params) => {
  return apiClient({
    method: 'get',
    url: 'bi/dashboard2/dash/channels',
    params,
  })
}

export const getDashboardData = ({ url, params }) => {
  return apiClient({
    method: 'get',
    url: `bi/dashboard2/dash_ord_date/chart/${url}`,
    params,
  })
}

export const countSales = (params) => {
  return apiClient({
    method: 'get',
    url: 'bi/dashboard2/dash/sales-count',
    params,
  })
}

export const downloadSales = (params) => {
  return apiClient({
    method: 'get',
    url: 'bi/dashboard2/dash/sales-excel',
    responseType: 'arraybuffer',
    params,
  })
}

export const getShippingOrderFromDashboard = ({ signal, order_id }) => {
  return apiClient({
    signal,
    method: 'get',
    url: `bi/dashboard2/internal/order/detail/${order_id}`,
  })
}
